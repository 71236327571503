import { startTime } from './gps-fake-data';

export const VideoRequestFakeData = {
  id: 123,
  start: startTime.toISOString(),
  end: startTime.add(600, 'second').toISOString(),
  cameras: [
    {
      name: 'Forward Facing',
      framerate: 15,
      severity: 1,
      src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA17/master.m3u8',
      isPlayerHide: false,
      isVideoAdjustmentsOpen: false,
      brightness: 1,
      contrast: 1,
      saturate: 1,
    },
    {
      name: 'Driver',
      framerate: 15,
      severity: 1,
      src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA18/master.m3u8',
      isPlayerHide: false,
      isVideoAdjustmentsOpen: false,
      brightness: 1,
      contrast: 1,
      saturate: 1,
    },
    {
      name: 'Front Door',
      framerate: 15,
      severity: 1,
      src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA19/master.m3u8',
      isPlayerHide: false,
      isVideoAdjustmentsOpen: false,
      brightness: 1,
      contrast: 1,
      saturate: 1,
    },
    {
      name: 'Front 360 - Boarding Area',
      framerate: 15,
      severity: 1,
      src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA20/master.m3u8',
      isPlayerHide: false,
      isVideoAdjustmentsOpen: false,
      brightness: 1,
      contrast: 1,
      saturate: 1,
    },
    {
      name: 'Front 360 - Wheelchair',
      framerate: 5,
      severity: 1,
      src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA21/master.m3u8',
      isPlayerHide: false,
      isVideoAdjustmentsOpen: false,
      brightness: 1,
      contrast: 1,
      saturate: 1,
    },
    {
      name: 'Front 360 - Front Seating',
      framerate: 5,
      severity: 1,
      src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA22/master.m3u8',
      isPlayerHide: false,
      isVideoAdjustmentsOpen: false,
      brightness: 1,
      contrast: 1,
      saturate: 1,
    },
    {
      name: 'Front to Rear',
      framerate: 5,
      severity: 1,
      src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA23/master.m3u8',
      isPlayerHide: false,
      isVideoAdjustmentsOpen: false,
      brightness: 1,
      contrast: 1,
      saturate: 1,
    },
    {
      name: 'Rear 360 - Rear to Front',
      framerate: 5,
      severity: 1,
      src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA24/master.m3u8',
      isPlayerHide: false,
      isVideoAdjustmentsOpen: false,
      brightness: 1,
      contrast: 1,
      saturate: 1,
    },
    {
      name: 'Rear 360 - Rear Door',
      framerate: 5,
      severity: 1,
      src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA25/master.m3u8',
      isPlayerHide: false,
      isVideoAdjustmentsOpen: false,
      brightness: 1,
      contrast: 1,
      saturate: 1,
    },
    {
      name: 'Rear 360 - Rear Entry Way',
      framerate: 5,
      severity: 1,
      src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA26/master.m3u8',
      isPlayerHide: false,
      isVideoAdjustmentsOpen: false,
      brightness: 1,
      contrast: 1,
      saturate: 1,
    },
    {
      name: 'Rear 360 - Rear Seating',
      framerate: 5,
      severity: 1,
      src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA27/master.m3u8',
      isPlayerHide: false,
      isVideoAdjustmentsOpen: false,
      brightness: 1,
      contrast: 1,
      saturate: 1,
    },
    {
      name: 'Street Side Rear',
      framerate: 15,
      severity: 1,
      src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA28/master.m3u8',
      isPlayerHide: false,
      isVideoAdjustmentsOpen: false,
      brightness: 1,
      contrast: 1,
      saturate: 1,
    },
    {
      name: 'Curb Side Rear',
      framerate: 15,
      severity: 1,
      src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA29/master.m3u8',
      isPlayerHide: false,
      isVideoAdjustmentsOpen: false,
      brightness: 1,
      contrast: 1,
      saturate: 1,
    },
    {
      name: 'Street Side',
      framerate: 15,
      severity: 1,
      src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA30/master.m3u8',
      isPlayerHide: false,
      isVideoAdjustmentsOpen: false,
      brightness: 1,
      contrast: 1,
      saturate: 1,
    },
    {
      name: 'Curb Side',
      framerate: 15,
      severity: 1,
      src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA31/master.m3u8',
      isPlayerHide: false,
      isVideoAdjustmentsOpen: false,
      brightness: 1,
      contrast: 1,
      saturate: 1,
    },
    {
      name: 'Rear View',
      framerate: 15,
      severity: 1,
      src: 'https://tsi-video.trailheadtechnology.com/videos/AnnArbor20Cams/AnnArbor20Cams-CAMERA32/master.m3u8',
      isPlayerHide: false,
      isVideoAdjustmentsOpen: false,
      brightness: 1,
      contrast: 1,
      saturate: 1,
    },
  ],
};
