/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { TViewLayout } from '@th-common/interfaces/player/player';
import { IVideoAdjustments } from '@th-common/interfaces/player/video-source-info';
import { IVideoBookmark } from '@th-common/interfaces/video/bookmark';
import { IGpsData } from '@th-common/interfaces/video/gps-data';
import { IVideoRequest } from '@th-common/interfaces/video/video-request';
import dayjs, { Dayjs } from 'dayjs';

import { api as apiVideoPlayback } from './api';
import { startTime } from './gps-fake-data';

export interface IVideoPlaybackState {
  currentTimelineValue: number;
  clipDateTime: [string, string];
  videoQueryId: number;
  gpsData: IGpsData[];
  videoRequest: IVideoRequest;
  videoRequestStartDayJs: Dayjs;
  videoRequestEndDayJs: Dayjs;
  maxFps: number;
  bookmarks: IVideoBookmark[];
  activeBookmarkIndex: number | null;
  viewLayout: TViewLayout;
  isMetaDataPanelOpen: boolean;
}

const initialState: IVideoPlaybackState = {
  currentTimelineValue: 0,
  clipDateTime: [startTime.toISOString(), startTime.toISOString()],
  videoQueryId: 123,
  gpsData: [],
  videoRequest: {
    id: 123,
    start: startTime.toISOString(),
    end: startTime.add(600, 'second').toISOString(),
    cameras: [],
  },
  videoRequestStartDayJs: startTime,
  videoRequestEndDayJs: startTime.add(600, 'second'),
  maxFps: 15,
  bookmarks: [],
  activeBookmarkIndex: null,
  viewLayout: TViewLayout.Grid,
  isMetaDataPanelOpen: false,
};

export const slice = createSlice({
  name: 'videoPlayback',
  initialState,
  reducers: {
    setCurrentTimelineValue: (state, { payload }: { payload: number }) => {
      state.currentTimelineValue = payload;
    },
    setCurrentDateTime: (state, { payload }: { payload: Dayjs }) => {
      state.currentTimelineValue = payload.diff(dayjs(state.videoRequest.start), 'seconds') * state.maxFps;
    },
    setClipValue: (state, { payload }: { payload: [number, number] }) => {
      state.clipDateTime = [
        dayjs(state.videoRequest.start)
          .add(payload[0] / state.maxFps, 'second')
          .toISOString(),
        dayjs(state.videoRequest.start)
          .add(payload[1] / state.maxFps, 'second')
          .toISOString(),
      ];
    },
    setCenterFrame: (state) => {
      const videoRequestMax =
        dayjs(state.videoRequest.end).diff(dayjs(state.videoRequest.start), 'seconds') * state.maxFps;
      const middleFrame = Math.floor(videoRequestMax / 2);
      state.currentTimelineValue = middleFrame;
    },
    addBookmark: (state) => {
      const bookmark = {
        value: state.currentTimelineValue.toString(),
        saved: false,
      };

      if (state.activeBookmarkIndex !== null) {
        const activeBookmark = state.bookmarks[state.activeBookmarkIndex];
        if (activeBookmark.saved) {
          state.bookmarks = [...state.bookmarks, bookmark];
          state.activeBookmarkIndex = state.bookmarks.length - 1;
          return;
        }
        state.bookmarks[state.activeBookmarkIndex] = bookmark;
        return;
      }

      state.bookmarks = [...state.bookmarks, bookmark];
      state.activeBookmarkIndex = state.bookmarks.length - 1;
    },
    saveActiveBookmark: (state, { payload }: { payload: IVideoBookmark }) => {
      if (state.activeBookmarkIndex !== null) {
        state.bookmarks[state.activeBookmarkIndex] = {
          ...payload,
          saved: true,
        };
      }

      state.activeBookmarkIndex = null;
    },
    cancelActiveBookmark: (state) => {
      if (state.activeBookmarkIndex !== null && !state.bookmarks[state.activeBookmarkIndex].saved) {
        state.bookmarks = state.bookmarks.filter((_, index) => index !== state.activeBookmarkIndex);
      }

      state.activeBookmarkIndex = null;
    },
    removeActiveBookmark: (state) => {
      state.bookmarks = state.bookmarks.filter((_, index) => index !== state.activeBookmarkIndex);
      state.activeBookmarkIndex = null;
    },
    setActiveBookmark: (state, { payload }: { payload: number | null }) => {
      state.activeBookmarkIndex = payload === null ? null : payload;
    },
    hidePlayer: (state, { payload }: { payload: string }) => {
      state.videoRequest.cameras = state.videoRequest.cameras.map((camera) => {
        if (camera.name === payload) {
          return {
            ...camera,
            isPlayerHide: true,
          };
        }
        return camera;
      });
    },
    showPlayer: (state, { payload }: { payload: string }) => {
      state.videoRequest.cameras = state.videoRequest.cameras.map((camera) => {
        if (camera.name === payload) {
          return {
            ...camera,
            isPlayerHide: false,
          };
        }
        return camera;
      });
    },
    setViewLayout: (state, { payload }: { payload: TViewLayout }) => {
      state.viewLayout = payload;
    },
    toggleMetaDataPanel: (state, { payload }: { payload: boolean }) => {
      state.isMetaDataPanelOpen = payload;
    },
    setVideoAdjustments: (state, { payload }: { payload: { cameraName: string; videoParams: IVideoAdjustments } }) => {
      const cameraInfo = state.videoRequest.cameras.find((camera) => camera.name === payload.cameraName);
      if (cameraInfo) {
        cameraInfo.brightness = payload.videoParams.brightness;
        cameraInfo.contrast = payload.videoParams.contrast;
        cameraInfo.saturate = payload.videoParams.saturate;
      }
    },
    setVideoSource: (state, { payload }: { payload: {
      file: File;
      durationInSeconds: number;
    }; }) => {
      state.videoRequest = {
        ...state.videoRequest,
        end: startTime.add(payload.durationInSeconds, 'second').toISOString(),
        cameras: [
          {
            name: 'File Source',
            framerate: 15,
            severity: 1,
            src: URL.createObjectURL(payload.file),
            isPlayerHide: false,
            isVideoAdjustmentsOpen: false,
            brightness: 1,
            contrast: 1,
            saturate: 1,
          },
        ],
      };
      state.videoRequestEndDayJs = startTime.add(payload.durationInSeconds, 'second');
    },
    resetVideoAdjustments: (state, { payload }: { payload: { cameraName: string } }) => {
      const cameraInfo = state.videoRequest.cameras.find((camera) => camera.name === payload.cameraName);
      if (cameraInfo) {
        cameraInfo.brightness = 1;
        cameraInfo.contrast = 1;
        cameraInfo.saturate = 1;
      }
    },
    toggleVideoAdjustmentsPanel: (state, { payload }: { payload: { cameraName: string } }) => {
      const cameraInfo = state.videoRequest.cameras.find((camera) => camera.name === payload.cameraName);
      if (cameraInfo) {
        cameraInfo.isVideoAdjustmentsOpen = !cameraInfo.isVideoAdjustmentsOpen;
      }
    },
    reset: () => initialState,
  },
  extraReducers: (builder: ActionReducerMapBuilder<IVideoPlaybackState>) => {
    builder
      .addMatcher(apiVideoPlayback.endpoints.getGPSData.matchFulfilled, (state, { payload }) => {
        state.gpsData = payload.map((gpsData) => {
          const [
            date,
            lat,
            long,
            altitude,
            velocity,
            heading,
          ] = gpsData.split(';');

          return {
            date,
            lat: parseFloat(lat),
            long: parseFloat(long),
            altitude: parseFloat(altitude),
            velocity: parseFloat(velocity),
            heading: parseFloat(heading),
          };
        });
      })
      .addMatcher(apiVideoPlayback.endpoints.getDeviceVideoRequest.matchFulfilled, (state, { payload }) => {
        state.videoRequest = payload;
        state.videoRequestStartDayJs = dayjs(payload.start);
        state.videoRequestEndDayJs = dayjs(payload.end);
      });
  },
});

export default slice.reducer;
