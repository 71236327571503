import { IVideoSourceInfo } from '@th-common/interfaces/player/video-source-info';
import { IGpsDataParams, TGpsAPIData } from '@th-common/interfaces/video/gps-data';
import { IVideoRequest } from '@th-common/interfaces/video/video-request';
import { apiRoot } from '@th-common/store/api';
import dayjs from 'dayjs';

import { gpsFakeData } from './gps-fake-data';
import { VideoRequestFakeData } from './video-request-fake-data';

interface IVideoPlayback {
  id: number;
  start: string;
  end: string;
  cameras: IVideoSourceInfo[];
}

export const api = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getVideo: builder.query<IVideoPlayback, number>({
      query: (videoQueryId) => `video-query/${videoQueryId}`,
    }),
    getGPSData: builder.query<TGpsAPIData[], IGpsDataParams>({
      // query: ({
      //   startTime,
      //   endTime,
      // }) => {
      //   const fpsDateFormat = 'YYYYMMDDTHHmmss';
      //   const gpsStartTime = DateTimeUtils.noTimeZoneDateTime(startTime, fpsDateFormat);
      //   const gpsEndTime = DateTimeUtils.noTimeZoneDateTime(endTime, fpsDateFormat);

      //   return {
      //     url: `data/${gpsStartTime}_${gpsEndTime}.json`,
      //     method: 'GET',
      //   };
      // },
      queryFn: (arg: IGpsDataParams) => {
        return new Promise((resolve) => {
          resolve({
            data: gpsFakeData.filter((gpsData) => {
              const [gpsTime] = gpsData.split(';');
              const gpsDateTime = dayjs(gpsTime);

              return gpsDateTime.isAfter(arg.startTime) && gpsDateTime.isBefore(arg.endTime);
            }),
          });
        });
      },
    }),
    getDeviceVideoRequest: builder.query<IVideoRequest, number>({
      queryFn: (arg: number) => {
        return new Promise((resolve) => {
          resolve({
            data: VideoRequestFakeData,
          });
        });
      },
    }),
  }),
});
