import { TGpsAPIData } from '@th-common/interfaces/video/gps-data';
import { DateTimeUtils } from '@th-common/utils';
import dayjs from 'dayjs';

// import { startTime } from './slice';

export const defaultDate = '2014-11-13T12:30:00';
export const startTime = dayjs(defaultDate).set('millisecond', 0);

export const gpsFakeData: TGpsAPIData[] = [
  `${DateTimeUtils.noTimeZoneDateTime(startTime)};33.15964;-117.15239;218.1;28.0;350.0`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(5, 's'))};33.16017;-117.15245;224.5;29.0;354.6`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(10, 's'))};33.16029;-117.1525;224.5;27.0;340.8`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(15, 's'))};33.1604;-117.15259;227.3;23.0;325.6`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(20, 's'))};33.16082;-117.1532;231.7;25.0;310.6`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(25, 's'))};33.16124;-117.15381;228.4;26.0;295.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(30, 's'))};33.16136;-117.15393;226.8;24.0;280.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(35, 's'))};33.16179;-117.15419;225.0;26.0;265.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(40, 's'))};33.16175;-117.15434;222.9;24.0;250.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(45, 's'))};33.16168;-117.15494;215.2;26.0;235.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(50, 's'))};33.16121;-117.154865;214.6;24.0;220.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(55, 's'))};33.16074;-117.15479;213.6;23.0;205.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(60, 's'))};33.16047;-117.1548;211.9;25.0;190.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(65, 's'))};33.16031;-117.15484;211.0;26.0;175.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(70, 's'))};33.15992;-117.15536;185.4;26.0;160.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(75, 's'))};33.15975;-117.15528;204.2;24.0;145.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(80, 's'))};33.15945;-117.1557;202.4;26.0;130.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(85, 's'))};33.15906334;-117.15629;197.7;25.0;115.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(90, 's'))};33.15867667;-117.15688;194.2;24.0;100.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(95, 's'))};33.15829;-117.15747;191.8;24.0;85.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(100, 's'))};33.15806;-117.15778;191.0;26.0;70.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(105, 's'))};33.1577;-117.15808;190.3;26.0;55.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(110, 's'))};33.15745;-117.15823;190.2;25.0;40.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(115, 's'))};33.15684;-117.15855;189.6;24.0;25.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(120, 's'))};33.15654;-117.15864;189.5;23.0;10.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(125, 's'))};33.15573429;-117.1587429;188.9;25.0;355.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(130, 's'))};33.15492857;-117.1588457;188.5;24.0;340.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(135, 's'))};33.15412286;-117.1589486;188.0;23.0;325.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(140, 's'))};33.15331714;-117.1590514;187.5;22.0;310.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(145, 's'))};33.15251143;-117.1591543;186.7;21.0;295.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(150, 's'))};33.15170572;-117.1592571;186.1;0.0;6.1`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(155, 's'))};33.15251143;-117.1591543;186.7;0.0;6.1`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(160, 's'))};33.15331714;-117.1590514;187.5;0.0;6.1`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(165, 's'))};33.15412286;-117.1589486;188.0;0.0;6.1`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(170, 's'))};33.15492857;-117.1588457;188.5;0.0;6.1`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(175, 's'))};33.15573429;-117.1587429;188.9;25.0;6.1`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(180, 's'))};33.15654;-117.15864;189.5;24.0;6.1`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(185, 's'))};33.15684;-117.15855;189.6;25.0;14.1`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(190, 's'))};33.15745;-117.15823;190.2;24.0;23.7`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(195, 's'))};33.1577;-117.15808;190.3;28.0;26.7`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(200, 's'))};33.15806;-117.15778;191.0;0.0;34.9`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(205, 's'))};33.15829;-117.15747;191.8;0.0;48.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(210, 's'))};33.15867667;-117.15688;194.2;0.0;51.9`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(215, 's'))};33.15906334;-117.15629;197.7;0.0;51.9`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(220, 's'))};33.15945;-117.1557;202.4;0.0;51.9`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(225, 's'))};33.15975;-117.15528;204.2;22.0;49.5`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(230, 's'))};33.15992;-117.15507;208.0;20.0;46.0`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(235, 's'))};33.16031;-117.15484;211.0;27.0;26.3`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(240, 's'))};33.16047;-117.1548;211.9;20.0;11.8`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(245, 's'))};33.16074;-117.15479;213.6;29.0;1.8`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(250, 's'))};33.16121;-117.154865;214.6;25.0;352.4`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(255, 's'))};33.16168;-117.15494;215.2;25.0;352.4`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(260, 's'))};33.16175;-117.15434;222.9;24.0;82.1`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(265, 's'))};33.16179;-117.15419;225.0;28.0;72.3`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(270, 's'))};33.16136;-117.15393;226.8;25.0;153.2`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(275, 's'))};33.16124;-117.15381;228.4;28.0;140.1`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(280, 's'))};33.16082;-117.1532;231.7;27.0;129.4`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(285, 's'))};33.1604;-117.15259;227.3;25.0;129.4`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(290, 's'))};33.16029;-117.1525;224.5;22.0;145.6`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(295, 's'))};33.16017;-117.15245;224.5;30.0;160.8`,
  `${DateTimeUtils.noTimeZoneDateTime(startTime.add(300, 's'))};33.15958;-117.15239;217.8;28.0;175.1`,
];
